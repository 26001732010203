
#App {
  background-color: rgb(50,50,50);
}

/* React bootstrap Mods */
button {
  margin: 10px
}

.list-group-item {
  text-align: left;
}

.title-text {
  text-align: center;
}

.form-label, h1, h2, h3, h4, h5 {
  color: #eee;
}

.modal-label {
  color: #000;
}

hr {
  border: none;
    height: 1px;
    /* Set the hr color */
    color: #ffffff; /* old IE */
    background-color: rgb(255, 255, 255);
    border: 0px;
}

/* EVENT  */

#event-form {
  place-content: center flex-start;
  align-items: center;
  flex-flow: column nowrap;
  gap: 10px;
  padding: 0px;
  padding: 20px;
}

#event-name {
  margin-top: 10px;
  margin-bottom: 10px;
}

.eventform-section {
  margin-top: 20px;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1); /* IE */
  -moz-transform: scale(1); /* FF */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1); /* Opera */
  padding: 10px;
}

.form-checkbox-withtext {
  margin-top: 10px;
}

.form-checkbox-withtext label {
  margin-left: 10px;
  vertical-align: text-top;
}

/* ADMIN */

.admin-customprompt-isenabled {
  text-align: right;
}

.admin-customgroup-input {
  margin-top: 10px;
}

#admin-input-form-override {
  margin-right: 5px;
}